@media (max-width:769px) {
  .menu {
    display: none;
  }

  .button button {
    /* display: none; */
    padding: 7px 30px;
  }

  .service-part {
    display: block;
  }

  .design-brand {
    width: 90%;
    margin: 0 auto;
  }

  .web-app {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .project-management {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .service-part-2 {
    display: block;
    margin: 0px;
  }

  .SEO-marketing {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .industrial-solution {
    width: 90%;
    margin: 0 auto;
    margin-top: 20px;
  }

  .company-steps-part-first {
    display: block;
  }

  .company-first-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .company-second-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .company-third-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .company-steps-part-second {
    display: block;
  }

  .company-fourth-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .company-fifth-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .company-six-step {
    width: 95%;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .industries-service-part-first {
    display: block;
  }

  .healthcare {
    width: 95%;
    margin: 0 auto;
  }

  .heathcare-icon {
    width: 30%;
  }

  .bank {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .bank-icon {
    width: 30%;
  }

  .education {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .education-icon {
    width: 30%;
  }

  .industrial-service-part-second {
    display: block;
  }

  .retail {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .retail-icon {
    width: 30%;
  }

  .tourism {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .tourism-icon {
    width: 30%;
  }

  .automobile {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .automobile-icon {
    width: 30%;
  }

  .industrial-service-part-third {
    display: block;
  }

  .industree-media {
    width: 95%;
    margin: 0 auto;

  }

  .media-icon {
    width: 30%;
  }

  .textile {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .textile-icon {
    width: 30%;
  }

  .virtual {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .virtual-icon {
    width: 30%;
  }

  .about-us-counter {
    display: block !important;
  }

  .happy-client {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .properti-for-client {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .Experince {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .about-service {
    width: 100%;
    margin: 0 auto;
  }

  .about-us-child {
    width: 100%;
  }

  .expretise {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .about-us-tapas-animate {
    display: none;
  }

  .footer-hero-head {
    display: block;
    margin: 0 auto;
    padding: 50px 40px;
  }

  .footer-con-first {
    width: 100%;
    margin-top: 20px;
  }

  .footer-con-second {
    width: 100%;
  }

  .footer-con-third {
    width: 100%;
  }

  .footer-last-content {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .Heading-Main h1 {
    font-size: 105px;
  }

  .info {
    width: 95%;
  }

  .logo {
    width: 25%;
    margin-top: 10px;
  }

  .logo img {
    width: 100%;
  }

  .about-main-final {
    display: block !important;
    margin: 0 auto;
    padding: 0px !important;
  }

  .final-about-info {
    width: 100%;
  }

  .final-about-info h1 {
    text-align: center;
  }

  .final-about-img {
    width: 100%;
    height: auto;
    margin-top: 20px;
    /* margin:  0 auto; */
  }

  .final-contact-us-main {
    display: block;
  }

  .final-contact-company-info {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 10px;
  }

  .faqs .faq .faq-question {
    margin-left: 0px;
    margin-top: 30px;
  }

  .faq-answer {
    margin-left: 0px;
  }

  .final-about-child-step {
    padding: 5px 10px;
  }

  .indusrial-final-main-img-sec {
    display: none;
  }

  .indusrial-final-main-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px;
  }

  .final-indtrial-hero-main {
    position: relative;
    width: 100%;
    padding-left: 0px;
  }

  .final-industrial-hero-child-img {
    display: none;
  }

  .reseved-section {
    text-align: center;
  }

  .company-social-media {
    justify-content: center;
  }

  .email-input {
    /* margin: 0 auto; */
    /* display: block; */
    margin-bottom: 20px !important;
  }

  .final-about-main-change {
    padding: 0px !important;
  }

  .final-about-step {
    display: block;
    padding: 10px 30px;
  }

  .final-about-child-step {
    width: 100% !important;
    padding: 10px 0px;
    border-bottom: 1px solid #262626;
  }

  .final-about-count {
    display: block;
    padding: 0px;
    text-align: center;
  }

  .final-about-count-img {
    margin: 0 auto;
    padding: 10px;
  }

  .email-input {
    margin-left: 0px;
  }

  .footer-logo-sec {
    margin: 0 auto;
    display: block;
  }

  .logo2 img {
    margin-left: 20px;
  }

  .hero-main-page-back-animate {
    position: absolute;
    top: 35%;
    left: 32%;
    z-index: -1;
    opacity: 0.5;
    mix-blend-mode: screen;
  }
}

@media (min-width:768px) and (max-width:890px) {
  .footer-hero-head {
    display: block;
    margin: 0 auto;
    padding: 50px 40px;
  }

  .footer-logo-sec {
    margin: 0 auto;
    display: block;
  }

  .footer-con-first {
    width: 100%;
    margin-top: 20px;
    justify-content: space-around;
  }

  .footer-con-second {
    width: 100%;
    justify-content: space-around;
  }

  .logo {
    width: 20%;
  }

  .logo img {
    width: 100%;
  }

  .info {
    width: 95%;
  }

  .heathcare-icon {
    width: 50%;
  }

  .bank-icon {
    width: 50%;
  }

  .education-icon {
    width: 50%;
  }


  .retail-icon {
    width: 50%;
  }

  .tourism-icon {
    width: 50%;
  }

  .automobile-icon {
    width: 50%;
  }


  .media-icon {
    width: 50%;
  }

  .textile-icon {
    width: 50%;
  }

  .virtual-icon {
    width: 50%;
  }
  .virtual{
    width: 100% !important;
  }
  .faqs .faq .faq-question {
    margin-left: 0px;
    margin-top: 30px;
  }

  .faq-answer {
    margin-left: 0px;
  }

  .company-steps-part-first {
    grid-template-columns: 45% 45%;
  }
  .industries-service-part-first{
    grid-template-columns: auto auto;
    justify-content: center;
  }
  .menu-footer ul {
    /* list-style-type: none; */
    padding-left: 0px;
  }

  .footer-con-second ul {
    /* list-style-type: none; */
    padding-left: 0px;
  }

  .final-contact-company-info-main-sec {
    width: 100%;
  }

  .indusrial-final-main-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px;
  }

  .final-indtrial-hero-main {
    position: relative;
    width: 100%;
    padding-left: 0px;
  }

  .final-industrial-hero-child-img {
    display: none;
  }

  .indusrial-final-main-img-sec {
    display: none;
  }

  .button button {
    padding: 7px 30px;
    margin-top: 15px;
  }

  .final-about-info {
    margin-top: 0%;
  }

  .final-about-main-change {
    padding: 0px !important;
  }

  .final-about-img img {
    padding-left: 0px !important;
    padding: 10px !important;
  }
  .final-about-hrading {
    padding-left: 5px;
  }
}

@media (max-width:575px) and (min-width:500px) {
  .button button {
    display: none;

  }

  .button {
    width: 0%;
  }

  .navbar {
    width: 100% !important;
  }

  .StartUp p {
    margin-top: 10px;
  }

  .Enterprise p {
    margin-top: 10px;
  }

  .media p {
    margin-top: 10px;
  }

  .social-good p {
    margin-top: 10px;
  }
}

@media (max-width:550px) {
  .logo {
    width: 33%;
    margin-top: 10px;
  }

  .logo img {
    width: 100%;
  }

  .about-us-child {
    width: 80%;
    margin: 0 auto;
    padding-top: 0px;
    margin-top: 0px;
  }

  .about-us-main {
    display: block;
  }

  .about-us-counter {
    display: block !important;
  }

  .happy-client {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .properti-for-client {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .Experince {
    width: 90%;
    margin: 0 auto;
    margin-top: 10px;
  }

  .expretise {
    margin-left: 0px;
    margin-top: 10px;
  }

  .about-us-tapas-animate {
    width: 80%;
    margin-top: 20px !important;
    margin: 0 auto;
  }

  .company-steps-part-first {
    display: block;
  }

  .company-first-step {
    width: 95%;
    margin: 0 auto;
  }

  .company-second-step {
    width: 95%;
    margin: 0 auto;
  }

  .company-third-step {
    width: 95%;
    margin: 0 auto;
  }

  .company-steps-part-second {
    display: block;
  }

  .company-fourth-step {
    width: 95%;
    margin: 0 auto;
  }

  .company-fifth-step {
    width: 95%;
    margin: 0 auto;
  }

  .company-six-step {
    width: 95%;
    margin: 0 auto;
  }

  .industries-service-part-first {
    display: block;
  }

  .healthcare {
    width: 95%;
    margin: 0 auto;
  }

  .heathcare-icon {
    width: 40%;
  }

  .bank {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .bank-icon {
    width: 40%;
  }

  .education {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .education-icon {
    width: 40%;
  }

  .industrial-service-part-second {
    display: block;
  }

  .retail {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .retail-icon {
    width: 40%;
  }

  .tourism {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .tourism-icon {
    width: 40%;
  }

  .automobile {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .automobile-icon {
    width: 40%;
  }

  .industrial-service-part-third {
    display: block;
  }

  .industree-media {
    width: 95%;
    margin: 0 auto;

  }

  .media-icon {
    width: 40%;
  }

  .textile {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .textile-icon {
    width: 40%;
  }

  .virtual {
    width: 95%;
    margin: 0 auto;
    margin-top: 10px !important;
  }

  .virtual-icon {
    width: 40%;
  }

  .faqs {
    width: 100%;
    /* max-width: 768px; */
    margin: 0 auto;
    padding: 15px;
  }

  .about-service {
    width: 100%;
    margin: 0 auto;
  }

  .about-us-child {
    width: 100%;
  }

  .expretise {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }

  .social-good span {
    margin-left: 0px !important;
  }

  .footer-hero-head {
    display: block;
    margin: 0 auto;
    padding: 50px 10px;
  }

  .footer-con-first {
    width: 100%;
    margin-top: 20px;
  }

  .footer-con-second {
    width: 100%;
  }

  .footer-con-third {
    width: 100%;
  }

  .footer-last-content {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .Heading-Main h1 {
    font-size: 36px;
    line-height: 41.15px;
  }

  .service-hero h2,
  .industries-hero h2,
  .choose-us-hero h2,
  .hero-faq h2,
  .hero-client h2 {
    font-size: 28px;
    line-height: 38px;
  }

  .service-hero h2,
  .choose-us-hero,
  .choose-us-child {
    margin-top: 20px;

  }

  .service-hero p,
  .industries-hero p,
  .choose-us-hero p,
  .hero-faq p,
  .hero-client p {
    font-size: 14px;
    line-height: 24px;
  }

  .hero-client p {
    width: 100%;

  }

  .service-hero,
  .industries-hero,
  .choose-us-hero {
    text-align: center;
    padding: 20px 40px;
  }

  .design-brand-head img,
  .web-app-head img,
  .project-management img,
  .SEO-marketing-head img {
    width: 20%;
    max-width: 20%;
  }

  .our-works {
    margin-top: 35px;
  }

  .home-page-ser-main {
    margin-top: 0px;
  }

  .hero-section-main {
    padding-bottom: 30px;
  }

  .about-main-final {
    display: block !important;
    margin: 0 auto;
    padding: 0px !important;
  }

  .final-about-info {
    width: 100%;
  }

  .final-about-img {
    width: 100%;
    height: auto;
    margin-top: 20px;
    /* margin:  0 auto; */
  }

  .final-about-img img {
    padding: 0px;
  }

  .hero-main-page-back-animate {
    display: none;
  }

  .final-about-step {
    display: block;
    padding: 0px 0px;
  }

  .final-about-child-step {
    width: 100% !important;
    padding: 10px 0px;
  }

  .final-about-content {
    width: 100%;
  }

  .final-about-count {
    display: block;
    padding: 0px;
    text-align: center;
  }

  .final-about-count-img {
    margin: 0 auto;
    padding: 10px;
  }

  .final-about-hrading {
    padding-left: 0px !important;
  }

  .final-about-main-change {
    padding: 10px !important;
  }

  .final-about-img img {
    padding-left: 0px !important;
  }

  .final-about-main-hrading-part {
    width: 100%;
  }

  .company-social-media {
    justify-content: center;
  }

  .final-industrial-txt {
    padding: 0px !important;
  }
  .button {
    width: 0%;
  }
  .final-indusrial-main {
    padding: 20px !important;
  }

  .final-industrialtab-main {
    display: block !important;
  }

  .final-indusrial-textlite {
    width: 100% !important;
    padding: 10px !important;
  }

  .final-indusrial-textlite-info {
    width: 100%;
    padding: 10px !important;
  }

  .final-indtrial-hero-main {
    position: relative;
    width: 100%;
    padding-left: 0px;
  }

  .final-industrial-hero-child-img {
    display: none;
  }

  .indusrial-final-main-img-sec {
    display: none;
  }

  .indusrial-final-main-img {
    display: block;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 50px;
  }

  .final-contact-us-main {
    display: block;
  }

  .faqs .faq .faq-question {
    margin-left: 0px;
    margin-top: 30px !important;
  }

  .faq-answer {
    margin-left: 0px;
  }

  .sub-design-brand {
    padding: 15px 10px;
  }

  .sub-design-brand-info {
    padding: 10px 13px;
  }

  .sub-web-app-dev {
    padding: 15px 10px;
  }

  .sub-web-dev-info {
    padding: 10px 13px;
  }

  .sub-project-manage {
    padding: 15px 10px;
  }

  .sub-project-manage-info {
    padding: 10px 13px;
  }

  .sub-seo-marketing {
    padding: 15px 10px;
  }

  .sub-seo-marketing-info {
    padding: 10px 13px;
  }

  .final-contact-about-user {
    display: block;
  }

  .final-contact-user-name {
    width: 100%;
  }

  .final-contact-user-mail {
    width: 100%;
  }

  .final-contact-user-selection {
    display: block;
  }

  .final-contact-user-requiment {
    width: 100%;
  }

  .final-contact-company-info-main {
    padding: 0px !important;
  }

  .final-contact-company-info-main-sec {
    width: 100%;
  }

  .final-contact-company-info {
    margin-bottom: 10px;
    width: 100%;
  }

  .final-contact-user-details-form {
    width: 85%;
  }

  .menu-footer {
    margin-left: 0px;
  }

  .menu-footer ul {
    /* list-style-type: none; */
    padding-left: 0px;
  }

  .footer-con-second ul {
    /* list-style-type: none; */
    padding-left: 0px;
  }

  .footer-con-first {
    justify-content: space-between;
  }

  .footer-con-second {
    justify-content: space-between;
  }

  .StartUp p {
    margin-top: 10px;
  }

  .Enterprise p {
    margin-top: 10px;
  }

  .media p {
    margin-top: 10px;
  }

  .social-good p {
    margin-top: 10px;
  }

  .email-input {
    /* margin: 0 auto; */
    /* display: block; */
    margin-bottom: 20px !important;
  }

  .logo2 {
    width: 33%;
  }

  .final-footer-contant-main ul li {
    padding: 10px 20px;
  }

  .navbar {
    justify-content: space-between;
    width: 100% !important;
  }

  .logo {
    margin-top: -10px;
  }

  .button {
    display: none;
  }

  .email-input {
    margin-left: 0px;
  }

  .footer-logo-sec {
    margin: 0 auto;
    display: block;
  }

  .hero-page-product-for-you {
    display: none;
  }
  .final-industrial-hero-info-txt{
    font-size: 40px;
  }
}

@media (max-width:1130px) and (min-width:890px) {
  .final-industrial-hero-info-txt {
    font-size: 30px;
  }

  .final-industrial-hero-info-txt-sec {
    font-size: 14px;
  }

  .final-about-info {
    margin-top: 0%;
  }

  .final-industrial-hero-child-img {
    left: 5px;
    bottom: -4px !important;
  }

  .info {
    width: 64%;
  }
}

@media (max-width:353px) {
  .final-contact-company-info {
    display: block;
  }

  .final-contact-info-main-service {
    padding: 15px !important;
  }
}
body::-webkit-scrollbar {
  width: 5px;
  /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: rgb(52, 52, 52);
  /* color of the scroll thumb */
  /* border-radius: 20px;       roundness of the scroll thumb */
  /* border: 3px solid orange;  creates padding around scroll thumb */
}


/* Navbar Start */
.logo {
  width: 12%;
}

.logo img {
  width: 100%;
}

.navbar-nav {
  list-style-type: none;
  margin-top: 3px;
  margin: 0 auto !important;
}

.navbar-nav a {
  color: white !important;
}

.navbar-nav a:hover {
  background-color: #262626;
  border-radius: 8px;
}

.navbar-nav a:active {
  background-color: #FF5C00;
}

.navbar-nav li {
  display: inline-block;
  color: white !important;
}

.link-page {
  color: wheat;
}

.navbar-nav a {
  display: inline-block;
  padding: 10px 20px;
}

/* .menu ul {
  list-style-type: none;
  margin-top: 3px;
}
.menu ul a{
  color: white !important;
}
.menu ul a:hover {
  background-color: #262626;
  border-radius: 8px;
}

.menu ul a:active {
  background-color: #FF5C00;
}

.menu li {
  display: inline-block;
}

.menu ul a {
  display: inline-block;
  padding: 14px 20px;
} */

.button button {
  padding: 10px 20px;
  background-color: #ff5c00;
  border: none;
  border-radius: 8px;
  color: white;
  margin-top: 8px;
  transition: transform 0.3s;
  float: right;
}



.button button:hover {
  transform: scale(1.1);
}

.hero-head {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}




/* Navbar End */


/* Home Page Start */

.hero-section-main {
  background-image: url("./assests/Update-Tapas-Img/Hero_Section_bg.png");
  width: 100%;
  background-color: rgba(0, 0, 0, 0.50);
  padding-bottom: 50px;
}

.Heading-Main {
  padding-top: 50px;
  position: relative;
  width: 100% !important;
  /* text-align: center; */
}

.Heading-Main h1 {
  text-align: center;
  font-size: 120px;
  margin-top: 0px;
  font-weight: 600;
}

.StartUp button {
  margin: 10px 0px;
  padding: 10px 20px;
  background-color: #262626;
  color: white;
  border: none;
  border-radius: 8px;
}

.Enterprise button {
  margin: 10px 0px;
  padding: 10px 20px;
  background-color: #262626;
  color: white;
  border: none;
  border-radius: 8px;
}

.media button {
  margin: 10px 0px;
  padding: 10px 20px;
  background-color: #262626;
  color: white;
  border: none;
  border-radius: 8px;
}

.social-good button {
  margin: 10px 0px;
  padding: 10px 20px;
  background-color: #262626;
  color: white;
  border: none;
  border-radius: 8px;
}

.tiltComponent {
  height: 400px;
  width: 200px;
  background-color: blue;
}

.info {
  /* display: flex;
  justify-content: space-evenly; */
  width: 46%;
  background-color: hsla(0, 0%, 14%, 0.50);
  padding: 15px 20px;
  border-radius: 12px;
  margin: 0 auto;
  border: 1px solid #262626;
}

.works-btn button {
  position: relative;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 10px;
  background: linear-gradient(0deg, rgba(36, 36, 36, 0.2), rgba(36, 36, 36, 0.2)),
    linear-gradient(0deg, #333333, #333333);
  font-size: 18px;
  transition: transform 0.2s;
}





.works-btn button:hover {
  transform: scale(1.1);
}

.our-works {
  display: flex;
  justify-content: center;
  margin-top: 70px;
}
.about-work-btn{
  margin-right: 10px;
}
.home-page-service-first {
  display: flex;

}

.branding h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 7px;
  margin-bottom: 0px;
}

.home-page-service-second {
  display: flex;
}

.designing h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 7px;
  margin-bottom: 0px;
}

.home-page-service-third {
  display: flex;
}

.web-dev h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 7px;
  margin-bottom: 0px;
}

.home-page-service-four {
  display: flex;
}

.app-dev h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 7px;
  margin-bottom: 0px;
}

.home-page-service-five {
  display: flex;
}

.ios-app-dev h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.blockchain-dev h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.adverties h3 {
  margin-top: 5px;
  margin-right: 10px;
  margin-bottom: 0px;
  margin-left: 7px;
}

.home-page-service {
  display: flex;
  justify-content: space-between;
  /* background-color: #262626;*/
  padding: 15px 0px;
  /* position: fixed; */
}

.home-page-ser-main {
  background-color: #1919195b;
  margin-top: 20px;
  margin-bottom: 20px;
}

.text-bold-etalic h3 {
  font-weight: 300;
  font-style: italic;
}

.StartUp p {
  padding: 10px 20px;
  background-color: #262626;
  margin-bottom: 0px !important;
  text-align: center;
  border-radius: 5px;
  cursor: context-menu;
}

.Enterprise p {
  padding: 10px 20px;
  background-color: #262626;
  margin-bottom: 0px !important;
  text-align: center;
  border-radius: 5px;
  cursor: context-menu;
}

.media p {
  padding: 10px 20px;
  background-color: #262626;
  margin-bottom: 0px !important;
  text-align: center;
  border-radius: 5px;
  cursor: context-menu;
}

.social-good p {
  padding: 10px 20px;
  background-color: #262626;
  margin-bottom: 0px !important;
  text-align: center;
  border-radius: 5px;
  cursor: context-menu;
}

/* Home Page End */

/* About Us Page Start */

.about-us-button {
  margin-top: 50px;
}

.about-service {
  padding: 10px 20px;
  border: 1px solid #262626;
  color: white;
  background-color: transparent;
  /* border: none; */
  border-radius: 8px;
  transition: border 0.5s ease;

}

.about-service:hover {
  border: 1px solid #FF5C00;
}

.expretise {
  padding: 10px 20px;
  background-color: #FF5C00;
  color: white;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
  transition: background-color 0.8s ease;
  border: 1px solid #ff5c00;
}

.expretise:hover {
  background-color: transparent;
  border: 1px solid #262626;
}

.about-us-hero h2 {
  font-size: 40px;
  font-weight: 600;
}

.about-us-hero p {
  color: #999999;
}

.about-us-main {
  padding: 50px 20px;
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to top right, rgba(144, 144, 144, 0) 75%, #ff5e0057 100%);
}

.happy-client {
  background: linear-gradient(0deg, #1A1A1A, #1A1A1A),
    linear-gradient(0deg, #262626, #262626);
  border: 1px solid #262626;
  width: 32%;
  padding: 18px 30px;
  border-radius: 5px;
  text-align: center;
}

.happy-client p {
  color: #999999;
  margin-bottom: 0px;
}

.happy-client h2 {
  margin-bottom: 0px;
}

.properti-for-client {
  background: linear-gradient(0deg, #1A1A1A, #1A1A1A),
    linear-gradient(0deg, #262626, #262626);
  border: 1px solid #262626;
  width: 32%;
  padding: 18px 30px;
  border-radius: 5px;
  text-align: center;
}

.properti-for-client p {
  color: #999999;
  margin-bottom: 0px;
}

.properti-for-client h2 {
  margin-bottom: 0px;
}

.Experince {
  background: linear-gradient(0deg, #1A1A1A, #1A1A1A),
    linear-gradient(0deg, #262626, #262626);
  border: 1px solid #262626;
  width: 32%;
  padding: 18px 30px;
  border-radius: 12px;
  text-align: center;
}

.Experince p {
  color: #999999;
  margin-bottom: 0px;
}

.Experince h2 {
  margin-bottom: 0px;
}

.about-us-child {
  width: 59%;
  padding-top: 100px;
}

.about-us-counter {
  margin-top: 60px;
}

.about-us-tapas-animate {
  width: 40%;
}

.about-us-tapas-animate img {
  width: 95%;
}


/* About Us Page End */

/* Service Page Start */

.service-hero {
  text-align: center;
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  background-color: rgba(0, 0, 0, 0.50);
  padding: 40px 40px;
}

.service-hero h2,
.industries-hero h2,
.choose-us-hero h2,
.hero-faq h2,
.hero-client h2 {
  font-weight: 600;
}

.service-hero p,
.industries-hero p,
.choose-us-hero p,
.hero-faq p {
  color: #E6E6E6;
  font-weight: 400;
}

.design-brand-head,
.web-app-head,
.project-management-head,
.SEO-marketing-head {
  display: flex;
  align-items: center;
}

.design-brand-head h3,
.web-app-head h3,
.project-management h3,
.SEO-marketing-head h3 {
  margin-left: 15px;
  font-size: 20px;
}

.design-brand p,
.web-app p,
.project-management p,
.SEO-marketing p {
  margin-top: 18px;
  color: #999999;
}

.design-brand,
.web-app,
.project-management,
.SEO-marketing {
  background-color: #191919;
  padding: 15px 20px;
  border-radius: 12px;
  width: 32%;
  border: 1px solid #262626;
}

.design-brand:hover {
  background-color: #262626;
  /* background: rgb(38, 38, 38);
  background: linear-gradient(117deg, rgba(38, 38, 38, 1) 0%, rgba(17, 17, 17, 1) 100%, rgba(0, 0, 0, 1) 100%); */
}

.web-app:hover {
  background-color: #262626;

}

.project-management:hover {
  background-color: #262626;
}

.SEO-marketing:hover {
  background-color: #262626;
}

.service-part {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}

.industrial-solution {
  width: 66%;
  background-image: url(./assests/Update-Tapas-Img/service-card-bg.png);
  padding: 25px 25px;
  border-radius: 8px;
  background-size: cover;
}

.industrial-solution-heading-btn button {
  /* border: none; */
  background-color: #141414;
  color: white;
  border: 1px solid #262626;
  padding: 10px 20px;
  border-radius: 8px;
  transition: border 0.5s ease;

}

.industrial-solution-heading-btn button:hover {
  border: 1px solid #FF5C00;
}

.industrial-solution-heading h3 {
  font-size: 20px;
  margin-top: 10px;
}

.industrial-solution-head {
  display: flex;
  justify-content: space-between;
}

.industrial-solution p {
  margin-top: 40px;
  text-align: center;
}

.service-part-2 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}




/* Service Page End */


/* Indindustries Page Start */

.industries-hero {
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  background-color: rgba(0, 0, 0, 0.50);
  text-align: center;
  padding: 40px 40px;
  margin-top: 5%;
}

.heathcare-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.heathcare-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.healthcare h3 {
  text-align: center;
  margin-top: 50px;
}

.healthcare {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
}

.healthcare:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);
}


.bank-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.bank-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.bank-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.bank {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.bank:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}


.education-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.education-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.education-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.education {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.education:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}

.industries-service-part-first {
  display: grid;
  /* justify-content: space-around; */
  grid-template-columns: auto auto auto;
  gap: 20px;
  margin-top: 20px;
}

.retail-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.retail-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.retail-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.retail {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.retail:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);
}

.tourism-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.tourism-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.tourism-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.tourism {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.tourism:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616, #000, #161616);

}

.automobile-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.automobile-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.automobile-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.automobile {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.automobile:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}

.industrial-service-part-second {
  display: flex;
  justify-content: space-around;
  margin-top: 19px;
}

.media-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.media-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.media-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.industree-media {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.industree-media:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}

.textile-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.textile-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.textile-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.textile {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.textile:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}

.virtual-icon img {
  border-radius: 50%;
  width: 100%;
  padding: 20px 20px;
  box-shadow: 16px 12px 9px 2px #FFFFFF1A inset;
  border: 8px solid #4D4D4D;
  background: #161616;

}

.virtual-icon {
  width: 30%;
  margin: 0 auto;
  display: block;
  /* height: 200px; */
}

.virtual-heading h3 {
  text-align: center;
  margin-top: 50px;
}

.virtual {
  /* width: 32%; */
  padding: 20px 20px;
  background-color: #161616;
  border-radius: 8px;
  transition: 2s ease;
}

.virtual:hover {
  background: #CFCFCF;
  background: linear-gradient(to top, #161616 0%, #000 50%, #161616 100%);

}

.industrial-service-part-third {
  display: flex;
  justify-content: space-around;
  margin-top: 19px;
}

/* Indindustries Page End */




/* Why Choose Us Page Start */

.choose-us-hero {
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  padding: 40px 40px;
  background-color: hsla(0, 0%, 0%, 0.50);
  text-align: center;
  margin-top: 50px;
}

.step-1 {
  padding: 10px 20px;
  background-color: #141414;
}

.step-1 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-1 {
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-first-step:hover .step-1 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-1-heading {
  padding: 35px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
}

.info-1-heading h4 {
  font-size: 20px;
}

.company-first-step:hover .info-1-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}

.company-first-step {
  /* width: 30%; */
}

.company-second-step {
  /* width: 30%; */
}

.step-2 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-2 {
  padding: 10px 20px;
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-second-step:hover .step-2 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-2-heading {
  padding: 35px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
}

.info-2-heading h4 {
  font-size: 20px;
}

.company-second-step:hover .info-2-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}


.company-third-step {
  /* width: 30%; */
}

.step-3 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-3 {
  padding: 10px 20px;
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-third-step:hover .step-3 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-3-heading {
  padding: 35px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
}

.info-3-heading h4 {
  font-size: 20px;
}

.company-third-step:hover .info-2-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}

.company-steps-part-first {
  /* display: flex;
  justify-content: space-around; */
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: center;
  /* grid-template-columns: repeat(auto-fit,minmax(200px,1fr)); */
  gap: 20px;
  margin-top: 20px;
}

.company-fourth-step {
  /* width: 30%; */
}

.step-4 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-4 {
  padding: 10px 20px;
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-fourth-step:hover .step-4 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-4-heading {
  padding:
    35px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
}

.info-4-heading h4 {
  font-size: 20px;
}

.company-fourth-step:hover .info-4-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}


.company-fifth-step {
  /* width: 30%; */
}

.step-5 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-5 {
  padding: 10px 20px;
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-fifth-step:hover .step-5 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-5-heading {
  padding: 35px;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
}

.info-5-heading h4 {
  font-size: 20px;
}

.company-fifth-step:hover .info-5-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}

.company-six-step {
  /* width: 30%; */
}

.step-6 p {
  font-size: 20px;
  margin-bottom: 0px;
}

.step-6 {
  padding: 10px 20px;
  border-left: 2px solid #FF5C00;
  background-color: transparent;
  /* transition: background 2s ease-in; */
}

.company-six-step:hover .step-6 {
  background: rgb(255, 92, 0);
  background: linear-gradient(90deg, rgba(255, 92, 0, 1) 34%, rgba(6, 0, 0, 0.031) 100%);
}

.info-6-heading {
  padding: 35px;
  border: 2px solid transparent;
  border-image-source: linear-gradient(#ff5c00, #161616);
  border-image-slice: 1;
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 84%, #ff5e0057 100%);
}

.info-6-heading h4 {
  font-size: 20px;
}

.company-six-step:hover .info-6-heading {
  background: linear-gradient(to top left, rgba(144, 144, 144, 0) 74%, #ff5e0057 100%);
}

.company-steps-part-second {
  /* display: flex; */
  /* justify-content: space-around; */
  display: grid;
  grid-template-columns: 30% 30% 30%;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

/* Why Choose Us Page End */

/* Projects Page Start */

.hero-projects {
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  text-align: center;
  padding: 40px 40px;
  background-color: hsla(0, 0%, 0%, 0.50);
  margin-top: 5%;
}

.hero-projects h2 {
  font-weight: 600;
}

.hero-projects p {
  font-weight: 400;
  color: #E6E6E6;
}

.project-landing-img {
  border-radius: 8px;
}

.project-landing-img img {
  width: 100%;
  /* height: 409px; */
}

.landing-1 {
  font-size: 16px;
  font-weight: 500;
  color: #CACACE;
  margin-bottom: 2px;
}

.release-date {
  font-size: 14px;
  font-weight: 400;
  color: #797C86;
}

.view-nft {
  margin-top: 10px;
}

.view-nft p {
  border-bottom: 1px solid white;
  /* width: 12%; */
  text-align: center;
  font-size: 14px;
  color: #F2F2F3;
  padding-bottom: 5px;
  transition: border-bottom 1s ease;
  display: flex;
}

.view-nft p:hover {
  border-bottom: 1px solid #ff5c00;
}

.first-pro-info {
  /* width: 446px; */
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.first-project {
  width: 100%;
}

.project-landing-img {
  width: 100%;
}

.projects-child {
  margin-top: 20px;
  /* padding: 20px 50px; */
  width: 100%;
}


/* Projects Page End */

/* Our Valuated Client Page Start */

.hero-client {
  text-align: center;
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  padding: 40px 40px;
  background-color: hsla(0, 0%, 0%, 0.50);
  margin-top: 5%;
}

.hero-client p {
  font-weight: 400;
  color: #999999;
}

.client-information img {
  padding: 20px;
  background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%),
    linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);
  border-radius: 12px;
  border: 1px solid;

  border-image-source: linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);


}

.client-information p {
  margin-top: 20px;
}

.first-client-info {

  padding: 30px 30px;
  background: linear-gradient(0deg, #191919, #191919),
    linear-gradient(0deg, #262626, #262626);
  border-radius: 12px;
}

.client-child {
  margin-top: 20px;
}

.name {
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 18px;
  color: #FFFFFF;
}

.client-post {
  font-weight: 300;
  font-size: 16px;
  color: #808080;
}

.first-client {
  width: 100%;
}

.first-client-personal-info {
  display: flex;
  margin-top: 20px;
}

.client-name {
  margin-left: 10px;
}

/* Our Valuated Client Page Start */


/* FAQs Page Start */

.hero-faq {
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  padding: 40px 40px;
  text-align: center;
  background-color: hsla(0, 0%, 0%, 0.50);
}

/* FAQs Page End */


/* Footer Page Start */

.facebook {
  padding: 18px;
  background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%),
    linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);

  border-radius: 8px;
}

.Twiter {
  padding: 18px;
  background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%),
    linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);

  border-radius: 8px;
}

.footer-hero-head {
  margin-top: 10px;
  background-color: #1919195b;
  padding: 16px 28px;
}

.LinkDen {
  padding: 18px;
  background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%),
    linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);
  border-radius: 8px;
}

.footer-social-media {
  display: flex;
  gap: 5px;
}

.footer-content-right {
  display: flex;
}

.email-input {
  padding: 14px 20px;
  background-color: transparent;
  border: none;
  /* border-radius: 8px; */
  color: #ffffff;
  margin-top: 10px;
  border-bottom: 1px solid #141414;
}

.email-input:focus {
  outline: none;
  border-bottom: 1px solid #FF5C00;
}

.menu-footer ul {
  list-style-type: none;
  /* padding-left: 0px; */
}

.footer-home {
  color: #999999;
  text-decoration: none;
  transition: color 1s ease;
}

.footer-home:hover {
  color: #FF5C00;
}

.about-footer {
  color: #999999;
  text-decoration: none;
  transition: color 1s ease;
}

.about-footer:hover {
  color: #FF5C00;
}

.About-us-menu ul {
  list-style-type: none;
}

.service-footer {
  color: #999999;
  text-decoration: none;
  transition: color 1s ease;
}

.service-footer:hover {
  color: #FF5C00;
}

.Service-menu ul {
  list-style-type: none;
}

.Contact-menu ul {
  list-style-type: none;
}

.content-footer {
  color: #999999;
  text-decoration: none;
  transition: color 1s ease;
}

.content-footer:hover {
  color: #FF5C00;
}

.footer-last-content {
  display: flex;
  justify-content: space-between;
  /* flex-direction: reverse; */
  /* padding: 10px 20px; */
}

.reseved-section {
  padding: 15px 10px;
}

.company-social-media {
  padding: 15px 10px;
  display: flex;
}

.footer-con-first {
  display: flex;
  width: 100%;
  justify-content: space-around;
  /* margin-top: 20px; */
}

.footer-con-second {
  display: flex;
  width: 100%;
  justify-content: space-around;
}


/* Footer Page Start */

/* Service Sub Page  Start */

.sub-design-brand-info p {
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 5px;
}

.sub-design-brand-info {
  font-size: 18px;
  line-height: 27px;
  padding: 15px 30px;
  border-left: 2px solid #ff5c00;
  margin-top: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, #1A1A1A 15%, rgba(0, 0, 0, 0.6194852941176471) 100%);
}
.sub-design-brand-info:hover{
  background-image: url(./assests/Update-Tapas-Img/final-ar-service-background.svg);
}
.sub-web-dev-info:hover{
  background-image: url(./assests/Update-Tapas-Img/final-ar-service-background.svg);
}
.sub-project-manage-info:hover{
  background-image: url(./assests/Update-Tapas-Img/final-ar-service-background.svg);
}
.sub-seo-marketing-info:hover{
  background-image: url(./assests/Update-Tapas-Img/final-ar-service-background.svg);
}
.sub-web-dev-info {
  font-size: 18px;
  line-height: 27px;
  padding: 15px 30px;
  border-left: 2px solid #ff5c00;
  margin-top: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, #1A1A1A 15%, rgba(0, 0, 0, 0.6194852941176471) 100%);
}

.sub-design-brand {
  border: 1px solid #141414;
  padding: 30px 40px;
}

.sub-design-brand h3 {
  margin-bottom: 20px;
}

.sub-web-app-dev {
  border: 1px solid #141414;
  padding: 30px 40px;
  margin-top: 20px;
}

.sub-web-app-dev h3 {
  margin-bottom: 20px;
}

.sub-web-dev-info p {
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 5px;
  padding-left: 10px;
}

.sub-project-manage {
  border: 1px solid #141414;
  padding: 30px 40px;
  margin-top: 20px;
}

.sub-project-manage h3 {
  margin-bottom: 20px;
}

.sub-project-manage-info p {
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 5px;
  padding-left: 10px;
}

.sub-project-manage-info {
  font-size: 18px;
  line-height: 27px;
  padding: 15px 30px;
  border-left: 2px solid #ff5c00;
  margin-top: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, #1A1A1A 15%, rgba(0, 0, 0, 0.6194852941176471) 100%);
}


.sub-seo-marketing {
  border: 1px solid #141414;
  padding: 30px 40px;
  margin-top: 20px;
}

.sub-seo-marketing h3 {
  margin-bottom: 20px;
}

.sub-seo-marketing-info p {
  margin-bottom: 0px;
  margin-left: 10px;
  padding: 5px;
  padding-left: 10px;
}

.sub-seo-marketing-info {
  font-size: 18px;
  line-height: 27px;
  padding: 15px 30px;
  border-left: 2px solid #ff5c00;
  margin-top: 20px;
  background: rgb(255, 255, 255);
  background: linear-gradient(90deg, #1A1A1A 15%, rgba(0, 0, 0, 0.6194852941176471) 100%);
}

/* Service Sub Page End */





/* Demo */

.faqs {
  width: 80%;
  /* max-width: 768px; */
  margin: 0 auto;
  padding: 15px;
}

.faqs .faq {
  margin: 15px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.faqs .faq .faq-question {
  position: relative;
  font-size: 20px;
  padding-right: 80px;
  transition: 0.4s ease;
  margin-left: 30px;
  cursor: context-menu;
  /* margin-top: 20px; */
}

.faqs .faq .faq-question::after {
  content: "+";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 30px;
  height: 30px;
  transition: 0.2s ease;
}

.faqs .faq .faq-answer {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: 0.2s ease;
}

.faqs .faq.open .faq-question {
  margin-bottom: 15px;
}


.faqs .faq.open .faq-question::after {
  content: "×";
}

.faqs .faq.open .faq-answer {
  max-height: 1000px;
  opacity: 1;
}

.faqs .faq.open .faq-question {
  color: #ff5c00;
}

.faq-box span {
  padding: 20px;
  ;
  text-align: center;
  border-radius: 12px;
  color: #ffffff;
  background: linear-gradient(180deg, #242424 0%, rgba(36, 36, 36, 0) 100%),
    linear-gradient(180deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);
}

.faqs .faq.open .faq-box span {
  color: #ff5c00;
}

/* Demo */

/* Final CSS */

.final-about-child-step {
  padding: 20px 20px;
}

.indusrial-final-main-img {
  display: none;
}

.company-steps-part-first ul li {
  list-style-type: circle;
}

.company-steps-part-second ul li {
  list-style-type: circle;
}

.collapse {
  visibility: visible !important;
}

.final-contact-company-info p {
  margin-bottom: 0px;
}

.final-indusrial-main {
  background-image: url(../public/img/abstract-design-industre.png);
  background-repeat: no-repeat;
}

.indusrial-final-main-img-sec {
  width: 100%;
}

/* .final-contact-company-info{
  padding: 15px 10px;
} */

.final-industrial-main-info {
  background-image: url("./assests/Update-Tapas-Img/Hero_Section_bg.png");
  padding: 20px 10px;
}

.final-about-info {
  margin-top: 5%;
}

.about-main-final {
  background-image: url("./assests/Update-Tapas-Img/Hero_Section_bg.png");
  padding: 20px 10px;
}

.final-contact-hero-section {
  background-image: url("./assests/Update-Tapas-Img/Hero_Section_bg.png");
  padding: 20px 10px;
}

.final-about-hrading {
  padding-left: 8px;
}

.logo2 {
  width: 12%;
  margin-top: 12px;
}

.email-input {
  height: 52px;
  margin-left: 20px;


}

.footer-logo-sec {
  display: flex;
  justify-content: space-between;
}

.logo2 img {
  width: 100%;
}

.finaly-contact-btn a {
  text-decoration: none !important;
}

.finaly-contact-btn button {
  padding: 10px 20px;
  transition: transform 0.2s;
}

.finaly-contact-btn button:hover {
  transform: scale(1.1);
}

#vehicle1 {
  padding: 20px;
}




/* Final CSS */


/* Demo Css */



.form-control {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  background-color: transparent;
  color: white;
  border: none;
}





input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 1px solid orangered;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  background-image: url(../public/img/black-tick-icon-26.png);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}


/* Demo Css */

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}


/* Footer Page Start */

.final-footer-contant-main ul li {
  list-style-type: none;
  display: inline-block;
  padding: 20px 0px 0px 20px;
  /* padding-top: 40px !important; */
}

.final-footer-contant-main ul {
  /* padding: 50px 0px; */
  /* padding-top: 40px !important; */
  padding-left: 0px;
}

.final-footer-contant-main a {
  color: white;
  transition: color 0.3s;
}

.final-footer-contant-main a:hover {
  color: #FF5C00;
}

/* Footer Page End */

.final-contact-input-main {
  border: none !important;
  border-bottom: 1px solid #585858 !important;
}

.final-contact-input-main:focus {
  border-bottom: 1px solid #FF5C00 !important;
}

.final-footer-get-mail button {
  /* background-color: #191919; */
  background-color: transparent;
  border: none;
  /* padding: 14px 15px; */
  /* border-radius: 5px; */
}

.send-icon {
  color: white;
  font-size: 22px;
}

.final-footer-form-main {
  padding-top: 0px;
  margin: 0px !important;
}

/* Manage Client Review */

.client-img {
  width: 10%;
}

.client-img img {
  width: 100%;
  border-radius: 50%;
}

/* Manage Client Review */


/* Main Page animation */

.hero-main-page-back-animate {
  position: absolute;
  top: 18%;
  left: 41%;
  z-index: -1;
  opacity: 0.8;
  mix-blend-mode: plus-lighter;
}



/* Main Page animation */
.about-us-tapas-animate {
  position: relative;
}


.full-page-animation img {
  position: absolute;
  top: 0px;
  opacity: 0.1;
  z-index: -1;
}

.footer-terms-condition {
  text-decoration: none;
  color: white;
  transition: color 0.2s;
}

.footer-terms-condition:hover {
  color: #FF5C00;
}
.trems-condition-part{
  margin-top: 15px;
}
.navbar-toggler img {
  color: white !important;
}

.final-contact-data-submit {
  padding: 10px 20px;
  transition: transform 0.2s;
}

.final-contact-data-submit:hover {
  transform: scale(1.1);
}

.first-final-client {
  width: 20%;
}

.child-terms-condition h3{
  text-align: center;
}

.ters-about-info h6{
  font-size: 20px;
  /* color: #ff8843; */
  font-weight: 600;
}


.sub-design-brand-info:hover .final-design-anime-first{
  color: white !important;
}
.sub-web-dev-info:hover .final-web-dev-anime-first{
  color: white !important;
}
.sub-project-manage-info:hover .final-project-manage-anime-first{
  color: white !important;
}
.sub-seo-marketing-info:hover .final-seo-marketing-anime-first{
  color: white !important;
}

.our-team-hero{
  background-image: url(./assests/Update-Tapas-Img/Hero_Section_bg.png);
  background-color: rgba(0, 0, 0, 0.50);
  padding: 40px 40px;
  text-align: center;
  margin-top: 10px;
}

.our-team-card-main{
  background: rgba( 0, 0, 0, 0.25 );
box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); 
backdrop-filter: blur( 4px );
-webkit-backdrop-filter: blur( 4px );
/* border-radius: 10px; */
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  background-color: black;
  color: white;
  font-family: 'Inter', sans-serif;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container {
  max-width: 1500px;
}
:root {
  --color-primary: #ff5c00;;
  --color-secondary: #1563ff;
  --color-gray: #333333;
  --color-white: #fff;
}

form {
  width: max-content;
  margin: 0 auto;
  padding-top: 4rem;
}

legend {
  font-weight: 500;
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
}

.checkboxes-container {
  clear: both;
  position: relative;
  /* padding: 4rem 2rem; */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.option {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  cursor: pointer;
  font-size:  1.125rem;
  color: var(--color-white);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.option input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox {
  position: relative;
  height: 28px;
  width: 28px;
  padding: 10px;
  background-color: #333333;
  border-radius: 5px;
  border: 1px solid #333333;
  transition: all 0.15s;
}

.option input:focus-visible ~ .checkbox {
  border: 1px solid #333333;
}

.option:hover input ~ .checkbox {
  border: 1px solid var(--color-primary);
}

/* ---------- */
/* Checkbox 1 */

.checkbox1:after {
  content: "";
  position: absolute;
  top: 13px;
  left: 6px;
  width: 0px;
  height: 0px;
  border: solid #333333;
  border-width: 2px 2px 0 0;
  opacity: 0;
  transform-origin: left top;
  transform: scaleX(-1) rotate(135deg);
  transition: width 0.08s ease 0.1s, height 0.1s ease, border-color 0.3s ease,
    opacity 0.1s ease 0.2s, border-width 0.1s ease;
}

.option input:checked ~ .checkbox1:after {
  opacity: 1;
  width: 7px;
  height: 14px;
  border-color: var(--color-primary);
  transition: width 0.1s ease, height 0.08s ease 0.1s, border-color 0.3s ease,
    border-width 0.1s ease;
}

/* ---------- */
/* Checkbox 2 */

.checkbox2:after {
  content: "";
  position: absolute;
  left: -3px;
  top: -3px;
  width: 2px;
  height: 2px;
  background-color: var(--color-gray);
  transition: all 0.15s;
}

.option input:checked ~ .checkbox2 {
  left: 6px;
  top: 2px;
  width: 12px;
  height: 20px;
  margin: 0 14px 8px 2px;
  border: solid var(--color-primary);
  border-width: 0 1px 1px 0;
  transform: rotate(45deg);
}

.option input:checked ~ .checkbox2:after {
  display: block;
  left: -4px;
  top: 2px;
  width: 2px;
  height: 2px;
  transform: scale(9);
}

.option input:checked:hover ~ .checkbox2,
.option input:checked:focus ~ .checkbox2 {
  border-width: 0 2px 2px 0;
}

/* ---------- */
/* Checkbox 3 */

.checkbox3:after {
  content: "";
  position: absolute;
  top: 14px;
  left: 12px;
  width: 0px;
  height: 0px;
  border: solid;
  border-width: 2px 2px 0 0;
  opacity: 0;
  transform: scaleX(-1) rotate(135deg);
  animation: unchecked 0.15s ease-in;
}

.option input:checked ~ .checkbox3:after {
  opacity: 1;
  top: 2px;
  left: 8px;
  width: 10px;
  height: 18px;
  border-color: var(--color-primary);
  transform: scaleX(-1) rotate(135deg);
  animation: checked 0.3s;
}

@keyframes checked {
  0% {
    top: 2px;
    left: 8px;
    width: 0px;
    height: 0px;
    opacity: 0;
    border-color: var(--color-secondary);
    transform: scaleX(-1) rotate(135deg);
  }
  10% {
    width: 2px;
    height: 18px;
  }
  50% {
    border-color: var(--color-secondary);
    transform: scale(300%) scaleX(-1) rotate(135deg);
  }
  100% {
    width: 10px;
    height: 18px;
    opacity: 1;
    border-color: var(--color-primary);
    transform: scale(100%) scaleX(-1) rotate(135deg);
  }
}

@keyframes unchecked {
  0% {
    top: 2px;
    left: 8px;
    width: 10px;
    height: 18px;
    opacity: 1;
    border-width: 2px 2px 0 0;
    border-color: var(--color-white);
  }
  20% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    top: -15px;
    left: 0px;
    width: 30px;
    height: 40px;
    border-width: 15px 15px 0 0;
    opacity: 0;
  }
}
